const highLightBar = $(".highlight");
const navLinks = $(".nav_ul a");
const loadContainer = $('.load_container');


const highlightPill = function () {

  var actWidth = $(".nav_ul li.uk-active").width();

  var actPosition = $(".nav_ul li").closest('.uk-active').position();

  highLightBar.css({ "left": + actPosition.left, "width": actWidth });

  navLinks.click(function () {

    var position = $(this).parent().position();

    var width = $(this).parent().width();

    highLightBar.css({ "left": + position.left, "width": width });

  });
}

const highLightNav = function () {

  if (loadContainer.hasClass('faded')) {

    highlightPill();

  }
}


$(window).on('load', function () {

  setTimeout(() => {

    loadContainer.fadeOut("2000").addClass('faded');

    highLightNav();

  }, 1600);

})


$(document).ready(function () {


  const hamburgerMenu = $("#hamburger-3");

  hamburgerMenu.click(function () {
    $(this).addClass("is-active");
  });

  UIkit.util.on('#offcanvas-overlay', 'hidden', function () {
    hamburgerMenu.removeClass("is-active");
  });


  var offCanvas = $('#offcanvas-overlay')
  var mobileLi = $('.mobile_nav_ul li');

  mobileLi.each(function () {
    $(this).on('click', function () {
      setTimeout(() => {
        UIkit.offcanvas(offCanvas).hide();
      }, 500);

    })
  });

  function onSubmit(token) {
    document.getElementById("form").submit();
  }



  var navAnchors = $('.nav_ul a');

  navAnchors.each(function () {

    $(this).on('click', function () {
      setTimeout(() => {
        UIkit.scroll($(this)).scrollTo('#page_content');
      }, 500);
    })

  });


  UIkit.util.on('.nav_ul a', 'show', function () {

  });



});

document.fonts.onloadingdone = () => {
  highLightNav();
};

$(window).resize(function () {
  highlightPill();
});